import { Helmet } from 'react-helmet';
import '../assets/css/Calendar.css';
import { useGetCalendarContent } from '../hooks/useGetCalendarContent';
import { useGetCalendarEvents } from '../hooks/useGetCalendarEvents';

export const Calendar = (): JSX.Element => {
    const locale = localStorage.getItem('locale') || 'sv';
    const { content } = useGetCalendarContent(locale);
    const { events } = useGetCalendarEvents(locale);

    if (!events) return (<div>Loading...</div>);

    console.log(events);

    const filterEvents = (isUpcoming: boolean) => {
        const today = new Date();
        return events
            .filter(event => isUpcoming ? new Date(event.date) >= today : new Date(event.date) < today)
    };

    const upcomingEvents = filterEvents(true);
    const pastEvents = filterEvents(false);

    // sort
    upcomingEvents.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
    pastEvents.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());

    return (
        <>
            <Helmet>
                <title>Upcoming Events | French Group</title>
                <meta name="description" content="Check out our upcoming and past events." />
                <link rel="canonical" href="https://frenchgroup.se/calendar" />
                <meta property="og:title" content="Upcoming Events | French Group" />
                <meta property="og:description" content="Check out our upcoming and past events." />
                <meta property="og:url" content="https://frenchgroup.se/calendar" />
                <meta property="og:type" content="website" />
                <meta name="twitter:title" content="Upcoming Events | French Group" />
                <meta name="twitter:description" content="Check out our upcoming and past events." />
                <meta name="twitter:url" content="https://frenchgroup.se/calendar" />
                <meta name="twitter:card" content="summary" />
            </Helmet>
            <section id="calendar">
                <div className="container">
                    <h2 className="fade-in">{content?.upcoming_title || 'Upcoming'}</h2>
                    <div className="calendar__events fade-in">
                        {upcomingEvents.map((event, index) => (
                            <div key={index} className="calendar__event">
                                <div className="calendar__event-image">
                                    <img src={event.image} alt={event.title} height="220" width="268"/>
                                    <div className="calendar__date">
                                        <div className="calendar__day">{new Date(event.date).getDate()}</div>
                                        <div className="calendar__month">{new Date(event.date).toLocaleString('en-US', { month: 'short' })}</div>
                                    </div>
                                </div>
                                <div className="calendar__event-info">
                                    <h3>{event.title}</h3>
                                    <p className="event__description">{event.description}</p>
                                    <a href={event.url} target="_blank" rel="noreferrer" className="cta__button">{event.button_text}</a>
                                </div>
                            </div>
                        ))}
                    </div>
                    <h2 className="fade-in">{content?.recents_title || 'Recents'}</h2>
                    <div className="calendar__events fade-in recents">
                        {pastEvents.map((event, index) => (
                            <div key={index} className="calendar__event">
                                <div className="calendar__event-image">
                                    <img src={event.image} alt={event.title} height="220" width="268"/>
                                    <div className="calendar__date">
                                        <div className="calendar__day">{new Date(event.date).getDate()}</div>
                                        <div className="calendar__month">{new Date(event.date).toLocaleString('en-US', { month: 'short' })}</div>
                                    </div>
                                </div>
                                <div className="calendar__event-info">
                                    <h3>{event.title}</h3>
                                    <p className="event__description">{event.description}</p>
                                    <a href={event.url} target="_blank" rel="noreferrer" className="cta__button">{event.button_text}</a>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        </>
    );
};