import { Helmet } from 'react-helmet';
import qr from '../assets/img/qr.png';
import arrow from '../assets/img/arrow.png';
import '../assets/css/Member.css';
import landing_image from '../assets/img/landing_image.png';
import { useGetMemberContent } from '../hooks/useGetMemberContent';
import Markdown from 'react-markdown';

export const Member = (): JSX.Element => {
    const locale = localStorage.getItem('locale') || 'sv';
    const { content } = useGetMemberContent(locale);
    return (
        <>
            <Helmet>
                <title>Member | French Group</title>
                <meta name="description" content="Become a part of the French Group and get early access to our events and happenings." />
                <link rel="canonical" href="https://frenchgroup.se/member" />

                <meta property="og:title" content="Member | French Group" />
                <meta property="og:description" content="Become a part of the French Group and get early access to our events and happenings." />
                <meta property="og:url" content="https://frenchgroup.se/member" />
                <meta property="og:type" content="website" />

                <meta name="twitter:title" content="Member | French Group" />
                <meta name="twitter:description" content="Become a part of the French Group and get early access to our events and happenings." />
                <meta name="twitter:url" content="https://frenchgroup.se/member" />
                <meta name="twitter:card" content="summary" />
            </Helmet>
            <section id="member">
                <div className="wide__container">
                    <div className="member__content fade-in">
                        <div className="member__info">
                        <h2 className="fade-in">
                            {content?.title}
                        </h2>
                        <Markdown>
                            {content?.first_paragraph}
                        </Markdown>
                        <img src={qr} alt="QR code" height="200" className="qr"/>

                        <div className="press__button__text fly-bottom">
                            <p>
                                {content?.press_text}
                            </p>
                            <img src={arrow} height="75" width="75" alt="arrow pointing to the button" />
                            <a href="https://i.washere.io/join/a1dfb1a4-3d9c-42a4-8236-ef8b889ac396" 
                            target="_BLANK" rel="noreferrer" className="cta__button">
                                {content?.button_text}
                            </a>
                        </div>
                        </div>
                        <img src={landing_image} alt="Landing illustration" className="member__illustration"/>
                    </div>
                </div>
            </section>
        </>
    )
}