import { useEffect, useState } from "react";
import axios from "axios";

interface NavbarContent {
    about: string;
    calendar: string;
    contact: string;
    member: string;
    portfolio: string;
    locations: string;
    events: string;
    team: string;
    mobile_first_title: string;
    mobile_second_title: string;
    mobile_member: string;
}

export const useGetNavbarContent = (locale: string) => {
    const [navbarContent, setNavbarContent] = useState<NavbarContent | null>(null);
    
    useEffect(() => {

        const fetchData = async () => {
            try {
                const uri = process.env.REACT_APP_STRAPI_URL + "/api/navbar?locale=" + locale;
                const response = await axios.get(uri, {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${process.env.REACT_APP_STRAPI_API_KEY}`
                    },
                });

                setNavbarContent(response.data.data.attributes);
            } catch (error) {
                setNavbarContent({
                    about: "About",
                    calendar: "Calendar",
                    contact: "Contact",
                    member: "Member",
                    portfolio: "Portfolio",
                    locations: "Locations",
                    events: "Events",
                    team: "Team",
                    mobile_first_title: "What we do",
                    mobile_second_title: "Who we are",
                    mobile_member: "Become a member"
                })
            } 
        }

        fetchData();
    }, [locale]);

    return {navbarContent};
}