import { useEffect, useState } from "react";
import axios from "axios";

interface CalendarContent {
    upcoming_title: string;
    recents_title: string;
}

export const useGetCalendarContent = (locale: string) => {
    const [content, setContent] = useState<CalendarContent | null>(null);
    
    useEffect(() => {

        const fetchData = async () => {
            try {
                const uri = process.env.REACT_APP_STRAPI_URL + "/api/calendar?locale=" + locale;
                const response = await axios.get(uri, {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${process.env.REACT_APP_STRAPI_API_KEY}`
                    },
                });
                setContent(response.data.data.attributes);
            } catch (error) {
                setContent({
                    upcoming_title: "Upcoming",
                    recents_title: "Recents"
                })
            }
        }

        fetchData();
    }, [locale]);

    return {content};
}