import { Helmet } from "react-helmet"
import { Link } from "react-router-dom"
import '../assets/css/NotFound.css'

export const NotFound = (): JSX.Element => {
    return (
        <>
            <Helmet>
                <title>404 | French Group</title>
                <meta name="description" content="Page not found." />
            </Helmet>
            <section id="not-found">
                <div className="container">
                    <h1 className="title">404</h1>
                    <p>We're sorry, but the page you're looking for doesn't exist.</p>
                    <p className="button__wrap">
                        <Link to="/" className="cta__button">Go back to home</Link>
                    </p>
                </div>
            </section>
        </>
    )
}