import React, { useState } from 'react';
import MisterFrench from '../assets/img/mister-french.jpg';
import '../assets/css/Contact.css'; 
import { Helmet } from 'react-helmet';
import { useGetContactContent } from '../hooks/useGetContactContent';
import Markdown from 'react-markdown';

export const Contact = (): JSX.Element => {

    const [result, setResult] = useState("");
    const locale = localStorage.getItem("locale") || "sv";
    const { content } = useGetContactContent(locale);

    const onSubmit = async (event: any) => {
        event.preventDefault();
        setResult("Sending....");
        const formData = new FormData(event.target);
    
        formData.append("access_key", process.env.REACT_APP_WEB3_FORMS_API!);
    
        const response = await fetch("https://api.web3forms.com/submit", {
          method: "POST",
          body: formData
        });
    
        const data = await response.json();
    
        if (data.success) {
          setResult("Thank you for you message, we'll get back to you soon!");
          event.target.reset();
        } else {
          console.log("Error", data);
          setResult(data.message);
        }
    };

    return (
        <>
            <Helmet>
                <title>Contact | French Group</title>
                <meta name="description" content="Get in touch with us for your next event." />
                <link rel="canonical" href="https://frenchgroup.se/contact" />
                
                <meta property="og:title" content="Contact | French Group" />
                <meta property="og:description" content="Get in touch with us for your next event." />
                <meta property="og:url" content="https://frenchgroup.se/contact" />
                <meta property="og:type" content="website" />

                <meta name="twitter:title" content="Contact | French Group" />
                <meta name="twitter:description" content="Get in touch with us for your next event." />
                <meta name="twitter:url" content="https://frenchgroup.se/contact" />
                <meta name="twitter:card" content="summary" />
            </Helmet>
            <section id="contact" className="contact">
                <div className="contact__image-container">
                    <img src={MisterFrench} alt="Mister French" className="contact__image" />
                </div>
                <div className="contact__content fade-in">
                    <h1>
                        {content?.title}
                    </h1>
                    <Markdown>
                        {content?.first_paragraph}
                    </Markdown>
                    <form onSubmit={onSubmit}>
                        <div className="form-group">
                            <label htmlFor="name">
                                {content?.name_label}
                            </label>
                            <input type="text" id="name" name="name" placeholder={content?.name_placeholder} required />
                        </div>
                        <div className="form-group">
                            <label htmlFor="email">
                                {content?.email_label}
                            </label>
                            <input type="email" id="email" name="email" placeholder={content?.email_placeholder} required />
                        </div>
                        <div className="form-group">
                            <label htmlFor="message">
                                {content?.message_label}
                            </label>
                            <textarea id="message" name="message" 
                                    placeholder={content?.message_placeholder} 
                                    rows={4}
                                    required></textarea>
                        </div>
                        <button type="submit" className="btn__primary">
                            {content?.button_text}
                        </button>
                        <p className="form__result">{result}</p>
                    </form>
                </div>
            </section>
        </>
    )
}
