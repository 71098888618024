import { useEffect, useState } from "react";
import axios from "axios";

interface CalendarEvent {
    title: string;
    description?: string;
    image: string;
    button_text: string;
    url: string;
    date: Date;
    recurring: boolean;
    monday: boolean;
    tuesday: boolean;
    wednesday: boolean;
    thursday: boolean;
    friday: boolean;
    saturday: boolean;
    sunday: boolean;
}

export const useGetCalendarEvents = (locale: string) => {
    const [events, setEvents] = useState<CalendarEvent[] | null>(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const uri = `${process.env.REACT_APP_STRAPI_URL}/api/calendar-events?populate=*&sort=date:asc&locale=${locale}`;
                const response = await axios.get(uri, {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${process.env.REACT_APP_STRAPI_API_KEY}`
                    },
                });
                const loadedEvents = response.data.data.map((event: any) => ({
                    title: event.attributes.title,
                    description: event.attributes.description,
                    image: `${process.env.REACT_APP_STRAPI_URL}${event.attributes.image.data.attributes.url}`,
                    button_text: event.attributes.button_text,
                    url: event.attributes.url,
                    date: new Date(event.attributes.date),
                    recurring: event.attributes.recurring,
                    monday: event.attributes.monday,
                    tuesday: event.attributes.tuesday,
                    wednesday: event.attributes.wednesday,
                    thursday: event.attributes.thursday,
                    friday: event.attributes.friday,
                    saturday: event.attributes.saturday,
                    sunday: event.attributes.sunday
                }));
                setEvents(adjustRecurringEvents(loadedEvents));
            } catch (error) {
                console.error('Failed to fetch events:', error);
            }
        };

        fetchData();
    }, [locale]);

    return { events };
};

const adjustRecurringEvents = (events: CalendarEvent[]): CalendarEvent[] => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const dayOfWeek: number = today.getDay();
    const dayMapping: string[] = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

    const additionalEvents: CalendarEvent[] = [];

    for (let i = 0; i < events.length; i++) {
        if (!events[i].recurring) {
            continue;
        }

        const originalRecurringDate = new Date(events[i].date);
        originalRecurringDate.setHours(0, 0, 0, 0);

        dayMapping.forEach((day: string, index: number) => {
            if (events[i][day as keyof CalendarEvent]) {
                const offset = (index - dayOfWeek + 7) % 7;
                const nextEvent = {...events[i]};
                nextEvent.date = new Date(today);
                nextEvent.date.setDate(today.getDate() + offset);

                if (nextEvent.date >= originalRecurringDate) {
                    additionalEvents.push(nextEvent);

                    const followingWeekEvent = {...events[i]};
                    followingWeekEvent.date = new Date(nextEvent.date);
                    followingWeekEvent.date.setDate(followingWeekEvent.date.getDate() + 7);
                    additionalEvents.push(followingWeekEvent);
                }

                const previousWeekEvent = {...events[i]};
                previousWeekEvent.date = new Date(nextEvent.date);
                previousWeekEvent.date.setDate(previousWeekEvent.date.getDate() - 7);
                // not inserted yet
                if (previousWeekEvent.date >= originalRecurringDate && !additionalEvents.some((event) => event.title === previousWeekEvent.title)) {
                    additionalEvents.push(previousWeekEvent);
                }
            }
        });
    }

    return [...events, ...additionalEvents];
};
