import { useEffect, useState } from "react";
import axios from "axios";

interface ContactContent {
    title: string;
    first_paragraph: string;
    name_label: string;
    email_label: string;
    message_label: string;
    name_placeholder: string;
    email_placeholder: string;
    message_placeholder: string;
    button_text: string;
}

export const useGetContactContent = (locale: string) => {
    const [content, setContent] = useState<ContactContent | null>(null);
    
    useEffect(() => {

        const fetchData = async () => {
            try {
                const uri = process.env.REACT_APP_STRAPI_URL + "/api/contact?locale=" + locale;
                const response = await axios.get(uri, {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${process.env.REACT_APP_STRAPI_API_KEY}`
                    },
                });

                setContent(response.data.data.attributes);
            } catch (error) {
                setContent({
                    title: "Contact",
                    first_paragraph: "French group has assembled an exceptional portfolio of the most iconic venues for both private and corporate events.\\With over ten years of experience we create and proudly serve some of the largest happenings in Sweden. Our team takes care of all your needs, from tailored menus, to creative design, and detailed event planning. Regardless of the occasion we have the experience and knowledge to fulfill your desired event with outstanding results.",
                    name_label: "Name",
                    email_label: "Email",
                    message_label: "Message",
                    name_placeholder: "Enter your name",
                    email_placeholder: "example@frenchgroup.se",
                    message_placeholder: "Enter your message",
                    button_text: "Send message"
                })
            } 
        }

        fetchData();
    }, [locale]);

    return {content};
}