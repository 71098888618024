import { useEffect, useState } from "react";
import axios from "axios";

interface AboutContent {
    title: string;
    first_paragraph: string;
    second_paragraph: string;
    third_paragraph: string;
}

export const useGetAboutContent = (locale: string) => {
    const [content, setContent] = useState<AboutContent | null>(null);
    
    useEffect(() => {

        const fetchData = async () => {
            try {
                const uri = process.env.REACT_APP_STRAPI_URL + "/api/about?locale=" + locale;
                const response = await axios.get(uri, {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${process.env.REACT_APP_STRAPI_API_KEY}`
                    },
                });

                setContent(response.data.data.attributes);
            } catch (error) {
                setContent({
                    title: "About",
                    first_paragraph: "From the historic streets in Gamla Stan to the snow-covered peaks in Åre, from racing nightlife to sunsets at Visby Strand, French Group has become synonymous with quality, innovation and hospitality. Since our founding in 2013 we have been pioneers within the realm of creating places where people can meet, where the visits are not solely about having a meal and a drink, but a voyage, a history, an experience that enrich the soul.",
                    second_paragraph: "Our vision is clear: **_To become the leader in Nordic Entertainment._** To achieve this goal it is paramount that each employee, partner, and stakeholder share and embrace our core values: premium quality, passion, hospitality and unique experiences. These values are what drives us, what separates us from the rest and what creates the warm, inviting, and memorable feeling that is at the core in French Group.",
                    third_paragraph: "### 'VOTRE PLAISIR, NOTRE JOIE' Your pleasure, our happiness. It is not just a slogan; it is a promise from us to each and every guest, and a constant reminder why we do what we do."
                })
            } 
        }

        fetchData();
    }, [locale]);

    return {content};
}