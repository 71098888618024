import { useEffect, useState } from "react";
import axios from "axios";

interface MemberContent {
    title: string;
    first_paragraph: string;
    press_text: string;
    button_text: string;
}

export const useGetMemberContent = (locale: string) => {
    const [content, setContent] = useState<MemberContent | null>(null);
    
    useEffect(() => {

        const fetchData = async () => {
            try {
                const uri = process.env.REACT_APP_STRAPI_URL + "/api/member?locale=" + locale;
                const response = await axios.get(uri, {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${process.env.REACT_APP_STRAPI_API_KEY}`
                    },
                });

                setContent(response.data.data.attributes);
            } catch (error) {
                setContent({
                    title: "VOTRE PLAISIR, NOTRE JOIE",
                    first_paragraph: "Become a part of the French Group and step into our world. Through the card you will get early access to our events and happenings. By using the card you will earn rewards and get special offers every month. Welcome!",
                    press_text: "(or press this button)",
                    button_text: "Become a member"
                })
            } 
        }

        fetchData();
    }, [locale]);

    return {content};
}