import { Helmet } from 'react-helmet';
import '../assets/css/Home.css';
import { Newsletter } from '../components/Newsletter';


export const Home = (): JSX.Element => {
    return (
        <>
            <Helmet>
                <title>French Group</title>
                <meta
                    name="description"
                    content="Discover French Group, a leader in Nordic entertainment since 2013, renowned for exceptional quality, innovation, and hospitality. Experience our journey from the historic streets of Gamla Stan to the snowy peaks of Åre, where every visit is more than just a meal or a drink—it's a memorable adventure."
                />

                <meta property="og:title" content="French Group" />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://frenchgroup.se" />
                <meta property="og:description" content="Discover French Group, a leader in Nordic entertainment since 2013, renowned for exceptional quality, innovation, and hospitality. Experience our journey from the historic streets of Gamla Stan to the snowy peaks of Åre, where every visit is more than just a meal or a drink—it's a memorable adventure." />
                <meta property="og:site_name" content="French Group" />

                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="French Group" />
                <meta name="twitter:description" content="Discover French Group, a leader in Nordic entertainment since 2013, renowned for exceptional quality, innovation, and hospitality. Experience our journey from the historic streets of Gamla Stan to the snowy peaks of Åre, where every visit is more than just a meal or a drink—it's a memorable adventure." />
            </Helmet>
            <section id="home">
                <video playsInline autoPlay muted loop id="video" className="landing">
                    <source src="/videos/FrenchGroup_Hero_Maj_2024_D1.mp4" type="video/mp4" />
                </video>
                <Newsletter />
            </section>
        </>
    )
}