import arrow from '../assets/img/arrow.png';
import '../assets/css/Newsletter.css';

export const Newsletter = (): JSX.Element => {
    return (
        <div className="wide__container">
            <div className="newsletter__wrap">
                <div className="newsletter">
                    <img src={arrow} height="75" width="75" alt="arrow pointing to the button" />
                    <a href="https://gansub.com/s/Spajc/" 
                    target="_BLANK" rel="noreferrer" className="cta__button">Join Newsletter</a>
                </div>
            </div>
        </div>
    )
}