import '../assets/css/Portfolio.css';
import Strandvagen1 from '../assets/img/portfolio/Strandvägen1.webp';
import Milles from '../assets/img/portfolio/Milles.webp';
import MisterFrench from '../assets/img/portfolio/MisterFrench.webp';
import Strandbryggan from '../assets/img/portfolio/Strandbryggan.webp';
import LaBoca from '../assets/img/portfolio/LaBoca.webp';
import Kallis from '../assets/img/portfolio/Kallis.webp';
import Kronet from '../assets/img/portfolio/Krönet.webp';
import VisbyStrand from '../assets/img/portfolio/VisbyStrand.webp';
import MisterFrenchAre from '../assets/img/portfolio/MrFrenchÅre.webp';
import SigtunaStadshotell from '../assets/img/portfolio/SigtunaStadshotell.webp';
import Fredsgatan12 from '../assets/img/portfolio/Fredsgatan12.webp';
import StrandsVeranda from '../assets/img/portfolio/Strandsveranda.webp';
import f12 from '../assets/img/portfolio/f12.webp';
import f12studio from '../assets/img/portfolio/f12studio.webp';
import vafflan from '../assets/img/portfolio/vafflan.webp';
import { useGetNavbarContent } from '../hooks/useGetNavbarContent';

export const Portfolio = (): JSX.Element => {
    const locale = localStorage.getItem('locale') || 'sv';
    const { navbarContent } = useGetNavbarContent(locale);

    return (
        <section className="portfolio" id="portfolio">
            <div className="container">
                <div className="portfolio__header">
                    <h2>
                        {navbarContent?.portfolio}
                    </h2>
                </div>
                <div className="portfolio__locations">
                    <div className="portfolio__location fly-left">
                        <a href="https://strandvagen1.se/" target="_blank" rel="noreferrer">
                            <img src={Strandvagen1} alt="Strandvagen 1" className="portfolio__image" width="268" height="161"/>
                        </a>
                    </div>
                    <div className="portfolio__location fly-left">
                        <a href="https://restaurangmilles.se/" target="_blank" rel="noreferrer">
                            <img src={Milles} alt="Milles" className="portfolio__image" width="268" height="161"/>
                        </a>
                    </div>
                    <div className="portfolio__location fly-right">
                        <a href="https://mrfrench.se/" target="_blank" rel="noreferrer">
                            <img src={MisterFrench} alt="Mister French" className="portfolio__image" width="268" height="161"/>
                        </a>
                    </div>
                    <div className="portfolio__location fly-right">
                        <a href="https://strandbryggan.se/" target="_blank" rel="noreferrer">
                            <img src={Strandbryggan} alt="Strandbryggan" className="portfolio__image" width="268" height="161"/>
                        </a>
                    </div>

                    <div className="portfolio__location fly-left">
                        <a href="https://fredsgatantolv.se" target="_blank" rel="noreferrer">
                            <img src={Fredsgatan12} alt="Fredsgatan 12" className="portfolio__image" width="268" height="161"/>
                        </a>
                    </div>
                    <div className="portfolio__location fly-left">
                        <a href="https://laboca.se/" target="_blank" rel="noreferrer">
                            <img src={LaBoca} alt="La Boca" className="portfolio__image" width="268" height="161"/>
                        </a>
                    </div>
                    <div className="portfolio__location fly-right">
                        <a href="https://f12sthlm.se/" target="_blank" rel="noreferrer">
                            <img src={f12} alt="F12" className="portfolio__image" width="268" height="161"/>
                        </a>
                    </div>
                    <div className="portfolio__location fly-right">
                        <a href="https://fredsgatantolv.se/" target="_blank" rel="noreferrer">
                            <img src={f12studio} alt="F12 Studio" className="portfolio__image" width="268" height="161"/>
                        </a>
                    </div>

                    <div className="portfolio__location fly-left">
                        <a href="https://kallisvisby.se/" target="_blank" rel="noreferrer">
                            <img src={Kallis} alt="Kallis" className="portfolio__image" width="268" height="161"/>
                        </a>
                    </div>
                    <div className="portfolio__location fly-left">
                        <a href="https://www.kronetbyvisby.se/" target="_blank" rel="noreferrer">
                            <img src={Kronet} alt="Krönet" className="portfolio__image" width="268" height="161"/>
                        </a>
                    </div>
                    <div className="portfolio__location fly-right">
                        <a href="https://wisbystrand.se/" target="_blank" rel="noreferrer">
                            <img src={VisbyStrand} alt="Visby Strand" className="portfolio__image" width="268" height="161"/>
                        </a>
                    </div>
                    <div className="portfolio__location fly-right">
                        <a href="https://strandsveranda.se/" target="_blank" rel="noreferrer">
                            <img src={StrandsVeranda} alt="Strands Veranda" className="portfolio__image" width="268" height="161"/>
                        </a>
                    </div>

                    <div className="portfolio__location fly-bottom">
                        <a href="https://are.mrfrench.se/" target="_blank" rel="noreferrer">
                            <img src={MisterFrenchAre} alt="Mister French Åre" className="portfolio__image" width="268" height="161"/>
                        </a>
                    </div>
                    <div className="portfolio__location fly-bottom">
                        <a href="https://www.sigtunastadshotell.se/" target="_blank" rel="noreferrer">
                            <img src={SigtunaStadshotell} alt="Sigtuna Stadshotell" className="portfolio__image" width="268" height="161"/>
                        </a>
                    </div>
                    <div className="portfolio__location fly-bottom">
                        <a href="https://våfflan.se" target="_blank" rel="noreferrer">
                            <img src={vafflan} alt="Våfflan" className="portfolio__image" width="268" height="161"/>
                        </a>
                    </div>
                </div>
            </div>
        </section>
    )
}