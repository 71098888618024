import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Home } from './pages/Home';
import { Navbar } from './components/Navbar';
// import { Team } from './pages/Team';
import { Contact } from './pages/Contact';
import { Footer } from './components/Footer';
import { About } from './pages/About';
import { Calendar } from './pages/Calendar';
import { Locations } from './pages/Locations';
import { Events } from './pages/Events';
import { Member } from './pages/Member';
import { NotFound } from './pages/NotFound';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          {/* <Route path="/team" element={<Team />} /> */}
          <Route path="/contact" element={<Contact />} />
          <Route path="/about" element={<About />} />
          <Route path="/calendar" element={<Calendar />} />
          <Route path="/locations" element={<Locations />} />
          <Route path="/events" element={<Events />} />
          <Route path="/member" element={<Member />} />
          <Route path="/*" element={<NotFound />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
