import '../assets/css/About.css';
import ABOUT_1 from '../assets/img/about/1.jpg';
import ABOUT_2 from '../assets/img/about/2.jpg';
import { Helmet } from 'react-helmet';
import Markdown from 'react-markdown';
import { useGetAboutContent } from '../hooks/useGetAboutContent';

export const About = (): JSX.Element => {
    const locale = localStorage.getItem('locale') || 'sv';
    const { content } = useGetAboutContent(locale);
    return (
        <>
            <Helmet>
                <title>About | French Group</title>
                <meta name="description" content="Learn more about our company and our values." />
                <link rel="canonical" href="https://frenchgroup.se/about" />

                <meta property="og:title" content="About | French Group" />
                <meta property="og:description" content="Learn more about our company and our values." />
                <meta property="og:url" content="https://frenchgroup.se/about" />
                <meta property="og:type" content="website" />

                <meta name="twitter:title" content="About | French Group" />
                <meta name="twitter:description" content="Learn more about our company and our values." />
                <meta name="twitter:url" content="https://frenchgroup.se/about" />
                <meta name="twitter:card" content="summary" />
            </Helmet>
            <section id="about">
                <div className="container">
                    <div className="image__text">
                        <div className="left fly-left">
                            <h1 className="title">
                                {content?.title}
                            </h1>
                            <Markdown>
                                {content?.first_paragraph}
                            </Markdown>
                        </div>
                        <img src={ABOUT_1} alt="Placeholder"
                            height="400" width="600" className="fly-right"/>
                    </div>
                </div>
                <div className="text__section">
                    <div className="container">
                        <Markdown>
                            {content?.second_paragraph}
                        </Markdown>
                    </div>
                </div>
                <div className="container">
                    <div className="image__text">
                        <img src={ABOUT_2} alt="Placeholder"
                            height="400" width="600" className="fly-left"/>
                        <div className="right fly-bottom">
                            <Markdown>
                                {content?.third_paragraph}
                            </Markdown>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}