import { useEffect, useState } from "react";
import axios from "axios";

interface FooterContent {
    address: string;
    email: string;
    contact: string;
    footer_first_title: string;
    footer_second_title: string;
    locations: string;
    events: string;
    calendar: string;
    member: string;
    about: string;
    team: string;
}

export const useGetFooterContent = (locale: string) => {
    const [content, setContent] = useState<FooterContent | null>(null);
    
    useEffect(() => {

        const fetchData = async () => {
            try {
                const uri = process.env.REACT_APP_STRAPI_URL + "/api/footer?locale=" + locale;
                const response = await axios.get(uri, {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${process.env.REACT_APP_STRAPI_API_KEY}`
                    },
                });

                setContent(response.data.data.attributes);
            } catch (error) {
                setContent({
                    address: "Address",
                    email: "info@frenchgroup.se",
                    contact: "Contact",
                    footer_first_title: "What we do",
                    footer_second_title: "Who we are",
                    locations: "Locations",
                    events: "Events",
                    calendar: "Calendar",
                    member: "Become a member",
                    about: "About",
                    team: "The group"
                })
            } 
        }

        fetchData();
    }, [locale]);

    return {content};
}