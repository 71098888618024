import { Link } from 'react-router-dom';
import '../assets/css/Footer.css';
import whiteLogo from '../assets/img/logo_white.png';
import { InstagramOutlined } from '@ant-design/icons'
import { useGetFooterContent } from '../hooks/useGetFooterContent';

export const Footer = (): JSX.Element => {
    const locale = localStorage.getItem('locale') || 'sv';
    const { content } = useGetFooterContent(locale);

    return (
        <footer>
            <div className="container">
                <div className="upper-footer">
                    <div className="footer-left">
                        <img src={whiteLogo} alt="French Group Logo" height="42" width="230"></img>
                        <div className="contact">
                            <h4>
                                {content?.address}
                            </h4>
                            <span>
                                Skeppsbron 26
                                <br />
                                111 30 Stockholm
                            </span>
                            <h4>
                                {content?.contact}
                            </h4>
                            <a href={`mailto:${content?.email}`} target="_blank" rel="noreferrer">
                                {content?.email}
                            </a>
                        </div>
                        <div className="footer-socials">
                            <a href="https://instagram.com/frenchgroup_" target="_blank" rel="noreferrer"
                                aria-label="Instagram">
                                <InstagramOutlined style={{ fontSize: '30px', color: '#ccc' }} className="social__icon"/>
                            </a>
                        </div>
                    </div>
                    <div className="footer-right">
                        <div className="footer-links">
                            <h4>
                                {content?.footer_first_title}
                            </h4>
                            <Link to="/locations" onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
                                {content?.locations}
                            </Link>
                            <Link to="/events" onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
                                {content?.events}
                            </Link>
                            <Link to="/calendar" onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
                                {content?.calendar}
                            </Link>
                            <Link to="/member" onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
                                {content?.member}
                            </Link>
                        </div>
                        <div className="footer-links">
                            <h4>
                                {content?.footer_second_title}
                            </h4>
                            <Link to="/about" onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
                                {content?.about}
                            </Link>
                            {/* <Link to="/team" onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
                                {content?.team}
                            </Link> */}
                            <Link to="/contact" onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
                                {content?.contact}
                            </Link>
                            
                        </div>
                    </div>
                </div>
                <hr />
                <div className="lower-footer">
                    <p>Copyright ©️ 2024 Mister French Gruppen AB, org.nr.: <a href="https://www.allabolag.se/5592064488/mister-french-gruppen-ab" target="_blank" rel="noreferrer">559206-4488</a></p>
                    {/* <ul>
                        <li><a href="/tos">Terms of Service</a></li>
                        <li><a href="/privacy">Privacy Policy</a></li>
                    </ul> */}
                </div>
            </div>
        </footer>
    )
}