import React, { useState, useEffect, useRef } from 'react';
import '../assets/css/Navbar.css';
import { Link } from 'react-router-dom';
import blackLogo from '../assets/img/logo_black.png';
import whiteLogo from '../assets/img/logo_white.png';
import { useGetNavbarContent } from '../hooks/useGetNavbarContent';
import swe from '../assets/img/flags/swe.png';
import uk from '../assets/img/flags/uk.png';

export const Navbar = (): JSX.Element => {
    const [isOpen, setIsOpen] = useState(false);
    const [scrolled, setScrolled] = useState(false);
    const navRef = useRef<HTMLElement>(null);
    const dropdownRef = useRef<HTMLLIElement>(null);
    const locale = localStorage.getItem('locale') || 'sv';
    const { navbarContent } = useGetNavbarContent(locale);
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const changeLanguage = (lang: string) => {
        localStorage.setItem('locale', lang);
        window.location.reload();
    }

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth >= 768) {
                setIsOpen(false);
            }
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (isOpen) {
            document.body.classList.add('darken');
            document.body.style.overflow = 'hidden';
        } else {
            document.body.classList.remove('darken');
            document.body.style.overflow = 'visible';
        }

        const handleClickOutside = (event: any) => {
            if (navRef.current && !navRef.current.contains(event.target) && isOpen) {
                setIsOpen(false);
            }
            if (dropdownRef.current && !dropdownRef.current.contains(event.target) && dropdownOpen) {
                setDropdownOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOpen, dropdownOpen]);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 0) {
                setScrolled(true);
            } else {
                setScrolled(false);
            }
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <header ref={navRef} className={scrolled ? 'scrolled' : ''}>
            <div className="wide__container">
                <nav>
                    <div className="left__main__nav">
                        <div className={`menu-icon ${isOpen ? 'open' : ''}`} onClick={() => {setIsOpen(!isOpen)}}>
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                        <Link to="/locations"
                            className="nav__link"
                            onClick={() => {setIsOpen(false); window.scrollTo({ top: 0, behavior: 'smooth' })}}>
                            {navbarContent?.portfolio}
                        </Link>
                    </div>
                    <div className="logo">
                        <Link   to="/"
                                className="logo__link" 
                                onClick={() => {setIsOpen(false); window.scrollTo({ top: 0, behavior: 'smooth' })}}>
                            <img src={scrolled ? whiteLogo : blackLogo} alt="French Group logo" height="42" width="230" className="logo__image"/>
                        </Link>
                    </div>
                    <ul className={isOpen ? `left__nav left__nav__menu__open` : `left__nav left__nav__menu`}>
                        <div className={`menu-icon ${isOpen ? 'open' : ''}`} onClick={() => {setIsOpen(!isOpen)}}>
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                    
                        <div className="upper__left__nav left__nav__list">
                            <h4>{navbarContent?.mobile_first_title}</h4>
                            <li>
                                <Link to="/locations" onClick={() => {setIsOpen(false); window.scrollTo({ top: 0, behavior: 'smooth' })}}>
                                    {navbarContent?.locations}
                                </Link>
                            </li>
                            <li>
                                <Link to="/events" onClick={() => {setIsOpen(false); window.scrollTo({ top: 0, behavior: 'smooth' })}}>
                                    {navbarContent?.events}
                                </Link>
                            </li>
                            <li>
                                <Link to="/calendar" onClick={() => {setIsOpen(false); window.scrollTo({ top: 0, behavior: 'smooth' })}}>
                                    {navbarContent?.calendar}
                                </Link>
                            </li>
                            <li>
                                <Link to="/member" onClick={() => {setIsOpen(false); window.scrollTo({ top: 0, behavior: 'smooth' })}}>
                                    {navbarContent?.mobile_member}
                                </Link>
                            </li>
                        </div>
                        <div className="lower__left__nav left__nav__list">
                            <h4>
                                {navbarContent?.mobile_second_title}
                            </h4>
                            <li>
                                <Link to="/about" onClick={() => {setIsOpen(false); window.scrollTo({ top: 0, behavior: 'smooth' })}}>
                                    {navbarContent?.about}
                                </Link>
                            </li>
                            {/* <li>
                                <Link to="/team" onClick={() => {setIsOpen(false); window.scrollTo({ top: 0, behavior: 'smooth' })}}>
                                    {navbarContent?.team}
                                </Link>
                            </li> */}
                            <li>
                                <Link to="/contact" onClick={() => {setIsOpen(false); window.scrollTo({ top: 0, behavior: 'smooth' })}}>
                                    {navbarContent?.contact}
                                </Link>
                            </li>
                        </div>
                    </ul>
                    <ul className="nav-links right__nav">
                        <li className="dropdown" ref={dropdownRef}>
                            <span id="currentLanguage" onClick={() => setDropdownOpen(!dropdownOpen)}>
                                <img src={locale === 'sv' ? swe : uk} alt={locale === 'sv' ? "Swedish flag" : "Union Jack"} className="flag__icon" height="15" width="25" /> 
                                <span>{locale === 'sv' ? 'SV' : 'EN'}</span>
                                <span className="caret"></span>
                            </span>
                            {dropdownOpen && (
                                <div className="dropdown-content">
                                    <a href="/" onClick={() => changeLanguage('sv')}>
                                        <img src={swe} alt="Swedish flag" className="flag__icon" height="15" width="25" />
                                        <span>Svenska</span>
                                    </a>
                                    <a href="/" onClick={() => changeLanguage('en')}>
                                        <img src={uk} alt="Union Jack" className="flag__icon" height="15" width="25" />
                                        <span>English</span>
                                    </a>
                                </div>
                            )}
                        </li> 
                        <li>
                            <Link to="/about" onClick={() => {setIsOpen(false); window.scrollTo({ top: 0, behavior: 'smooth' })}}>
                                {navbarContent?.about}
                            </Link>
                        </li>
                        <li>
                            <Link to="/calendar" onClick={() => {setIsOpen(false); window.scrollTo({ top: 0, behavior: 'smooth' })}}>
                                {navbarContent?.calendar}
                            </Link>
                        </li>
                        <li>
                            <Link to="/contact" onClick={() => {setIsOpen(false); window.scrollTo({ top: 0, behavior: 'smooth' })}}>
                                {navbarContent?.contact}
                            </Link>
                        </li>
                        <li>
                            <Link className="cta__button" to="/member" onClick={() => {setIsOpen(false); window.scrollTo({ top: 0, behavior: 'smooth' })}}>
                                {navbarContent?.member}
                            </Link>
                        </li>
                    </ul>
                </nav>
            </div>
        </header>
    );
}