import { Portfolio } from "../components/Portfolio"
import { Helmet } from "react-helmet"

export const Locations = (): JSX.Element => {
    return (
        <> 
            <Helmet>
                <title>Locations | French Group</title>
                <meta name="description" content="Explore our locations and find the perfect spot for your next event." />
                <link rel="canonical" href="https://frenchgroup.se/locations" />

                <meta property="og:title" content="Locations | French Group" />
                <meta property="og:description" content="Explore our locations and find the perfect spot for your next event." />
                <meta property="og:url" content="https://frenchgroup.se/locations" />
                <meta property="og:type" content="website" />

                <meta name="twitter:title" content="Locations | French Group" />
                <meta name="twitter:description" content="Explore our locations and find the perfect spot for your next event." />
                <meta name="twitter:url" content="https://frenchgroup.se/locations" />
                <meta name="twitter:card" content="summary" />
            </Helmet>
            <Portfolio />
        </>
    )
}